import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import DynamicImages from '../components/dynamicImage';
const backendAddress = process.env.GATSBY_COCKPIT_URL;

class Gallery extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query developerQuery {
            developer: cockpit {
              singleton(name: "dev")
            }
          }
        `}
        render={data => (
          <Layout>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <p>
                <b>Blue Forest Development</b> powstała, by realizować
                inwestycje mieszkaniowe we Wrocławiu. Bazuje na doświadczeniu
                zdobytym na rynku łódzkim będąc powiązaną ze spółkami takimi jak
                Bonobo Development, Beskid Development oraz River development.
                Do zrealizowanych i trwających inwestycji należą:
              </p>
              {data.developer.singleton.investments.map((i, index) => (
                <div key={index} className="mb-5 mt-5">
                  <h2 className="head-underlined">{i.value.title}</h2>
                  <div className="row">
                    {i.value.gallery.map(img => (
                      <div className="col-md-6 col-12 mt-3">
                        <DynamicImages src={`${backendAddress}${img.path}`} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <p>
                <b>
                  Wszystkim naszym klientom zapewniamy kompleksową i
                  indywidualną obsługę. Wspieramy ich wybory, a także pomagamy w
                  procesie pozyskania finansowania na zakup mieszkania.
                </b>
              </p>
            </div>
          </Layout>
        )}
      />
    );
  }
}

export default Gallery;
